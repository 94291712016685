import './App.css';

function App() {
  const PartnersData = [
    { id: "1", corporatename: "ADAMA" },
    { id: "2", corporatename: "ADP" },
    { id: "3", corporatename: "Advance", subName: "Auto Parts" },
    { id: "4", corporatename: "AVEVA" },
    { id: "5", corporatename: "Bosch" },
    { id: "6", corporatename: "Carrier" },
    { id: "7", corporatename: "CHUBB" },
    { id: "8", corporatename: "Cognizant" },
    { id: "9", corporatename: "Colruyt" },
    { id: "10", corporatename: "DBS TECH" },
    { id: "11", corporatename: "D E Shaw", subName: "& Co" },
    { id: "12", corporatename: "epam" },
    { id: "13", corporatename: "experian" },
    { id: "14", corporatename: "F5 Networks" },
    { id: "15", corporatename: "firstsource" },
    { id: "16", corporatename: "HCLFoundation" },
    { id: "17", corporatename: "HSBC" },
    { id: "18", corporatename: "Infosys" },
    { id: "19", corporatename: "Inorbit" },
    {
      id: "20",
      corporatename: "Indian Oil",
      subName: "Corporation",
      subName2: "Limited",
    },
    { id: "21", corporatename: "InteractiveBrokers" },
    { id: "22", corporatename: "Invesco" },
    { id: "23", corporatename: "Micron" },
    { id: "24", corporatename: "Microsoft" },
    { id: "25", corporatename: "NOVARTIS" },
    { id: "26", corporatename: "NTT Data" },
    { id: "27", corporatename: "opentext" },
    { id: "28", corporatename: "Pegasystems" },
    { id: "29", corporatename: "PEPSICO" },
    { id: "30", corporatename: "Qualcomm" },
    { id: "31", corporatename: "Rotary" },
    { id: "32", corporatename: "savills" },
    { id: "33", corporatename: "SenecaGlobal" },
    { id: "34", corporatename: "servicenow" },
    { id: "35", corporatename: "SILICON", subName: "LABS" },
    { id: "36", corporatename: "STATE", subName: "STREET" },
    { id: "37", corporatename: "synchrony" },
    { id: "38", corporatename: "synopsys" },
    { id: "39", corporatename: "STELLANTIS" },
    { id: "40", corporatename: "UST" },
    { id: "41", corporatename: "valueMomentum" },
    { id: "42", corporatename: "verizon" },
    { id: "43", corporatename: "virtusa" },
    { id: "44", corporatename: "vmware" },
    { id: "45", corporatename: "WELLSFARGO" },
  ];

  const SpeakersData = [
    {
      name: "Madan Pillutla",
      role: "Dean & Professor, Indian School of Business.",
      imageSrc: "/speakers/madan.jpg",
    },
    {
      name: "Archana Suresh",
      role: "Director, Telangana Social Impact Group (T-SIG).",
      imageSrc: "/speakers/archana.png",
    },
    {
      name: "Jitendra Chakravarthy Putcha",
      role: "EVP & Global Head, LTI Mindtree",
      imageSrc: "/speakers/jitendra.jpg",
    },
    {
      name: "Neeraja Vijender",
      role: "Director & Head of Operations, HYSEA.",
      imageSrc: "/speakers/neeraja.jpg",
    },
    {
      name: "Divya Devarajan IAS",
      role: "Project Officer, Integrated Tribal Development Agency at Government of Telangana.",
      imageSrc: "/speakers/Divya.jpg",
    },
    {
      name: "Jebakumar Swvigaradoss",
      role: "Senior Director at Servicenow.",
      imageSrc: "/speakers/jebakumar.jpg",
    },
    {
      name: "Prashanth Nandella",
      role: "COO at Firstsource.",
      imageSrc: "/speakers/prashanth.jpg",
    },
    {
      name: "Nitin Sharma",
      role: "Technology Counsel (Sr. Director, Technology/Patent Counsel).",
      imageSrc: "/speakers/nithin.jpg",
    },
    {
      name: "Shri. G. Chandrashekar IFoS",
      role: "Sr. Director, Engineering at Qualcomm.",
      imageSrc: "/speakers/chandrashekar.jpg",
    },
    // Add more objects as needed
  ];
  return (
  <div>
    <div className='flex  font-custom background-img bg-opacity-95 min-h-screen text-white'>
    <div className='flex flex-col grow justify-center items-center px-6 md:px-0'>
    <div className='md:mt-32 mt-16 min-h-screen'>
    <img className='h-14 px-2 mx-auto bg-white rounded-md mb-4' src="/png/nirmaan_logo.png"></img>
    <div className='font-semibold md:text-xl text-lg mb-4 text-center '>Dec 12-13<sup>th</sup>, 2023(09:00 - 18:00 / GMT +05:30)| Hyderabad, Telangana</div>
    <div className='font-bold md:text-6xl text-3xl mb-4 text-center '><div>NIRMAAN SOCIAL IMPACT</div><div className='text-center mt-2'>CONCLAVE 3.0</div></div>
    <div className='font-semibold text-xl text-center'>Achieving An Economically Empowered And Knowledge Driven Society</div>
    <div className='grid grid-cols-2 md:grid-cols-3  gap-6 md:mt-20 mt-10'>
      <a href='#Event_details' className='  flex justify-center py-3 px-4 bg-white hover:bg-green-100 cursor-pointer text-black rounded-lg'><img className='my-auto pr-2 h-5' src="/png/home.png"></img><span className='my-auto'>Event Details</span></a>
      <a href='#Agenda' className='flex justify-center py-3 px-4 bg-white hover:bg-green-100 cursor-pointer text-black rounded-lg'><img className='my-auto pr-2 h-5' src="/png/calendar.png"></img><span className='my-auto'>Agenda</span></a>
      {/* <a href='#Speakers' className='flex py-3 px-4 bg-white hover:bg-green-100 cursor-pointer text-black rounded-lg'><img className='my-auto pr-2 h-6' src="/png/mic.png"></img><span className='my-auto'>Speakers</span></a> */}
      {/* <a href='#Partners' className='flex py-3 px-4 bg-white hover:bg-green-100 cursor-pointer text-black rounded-lg'><img className='my-auto pr-2 h-6' src="/png/partners.png"></img><span className='my-auto'>Partners</span></a> */}
      <a href='#Venue' className='flex justify-center py-3 px-4 bg-white hover:bg-green-100 cursor-pointer text-black rounded-lg'><img className='my-auto pr-2 h-6' src="/png/venue.png"></img><span className='my-auto'>Venue</span></a>
    </div>
    </div>

    {/* Event Details */}
    <div
            className="md:mx-32 rounded-[2rem] my-1 bg-white text-black "
            id="Event_details"
          >
            <div className="primary-color flex font-bold mt-16 md:text-5xl text-4xl  justify-center ">
              Event Details
            </div>
            <div className="md:mx-16 mx-6 md:mt-20 mt-10 text-2xl mb-4 text-red-700 font-bold">
              Welcome to the Nirmaan Social Impact Conclave Empowering Change,
              Inspiring Leadership
            </div>
            <div className="md:mx-16 mx-6 mb-4 text-xl hyphens-auto leading-7">
              At Nirmaan, we believe that true social impact is not just about
              inspiring others, but also fostering an environment that empowers
              individuals to create change. Our two-day conclave is thoughtfully
              designed to inspire leadership among our esteemed donors and
              partners, creating an environment of collaboration and innovation.
              With four insightful sessions each day, we aim to address critical
              aspects of societal progress, focusing on holistic education,
              career guidance, health and mental well-being, food initiatives,
              orphanages and shelter homes, women empowerment, diversity and
              inclusion, environmental sustainability, rural development, and
              disaster response.
            </div>
            <ul className="list-disc md:mx-20 mx-12 space-y-4 text-xl hyphens-auto leading-7">
              <li>
                <span className="font-semibold text-red-700">
                  Day 1 - Empowering Lives :{" "}
                </span>
                Experience a day focused on creating a positive impact through
                education, career guidance, health initiatives, and humanitarian
                efforts. Join us in shaping a brighter future for the
                underprivileged, empowering individuals to succeed in their
                careers, fostering community well-being, and providing care to
                those in need. Together, let's work towards a more compassionate
                and equitable world.
              </li>
              <li>
                <span className="font-semibold text-red-700">
                  Day 2 - Creating Impact :{" "}
                </span>
                On the second day of our conclave, we delve into critical areas
                that drive positive change. We highlight the significance of
                women's empowerment and inclusive hiring practices for societal
                advancement. Environmental sustainability takes center stage as
                we explore collective responsibilities and strategies for a
                greener future through ESG practices. Our commitment to rural
                communities leads us to discuss effective strategies for
                uplifting villages and fostering holistic development.
                Additionally, we shed light on disaster response measures and
                collaborative efforts to mitigate the impact of emergencies.
                Together, let's create a lasting impact and contribute to a more
                sustainable and resilient world.
              </li>
            </ul>








      {/* Agenda */}
      <div className='mt-28 pb-10 bg-gradient-to-b from-pink-50 to-blue-50' id="Agenda">
        <div className='flex font-bold py-16 md:text-5xl text-4xl justify-center  '>Agenda</div>
        {/* <div className='md:mx-20 ml-10 mr-8'> */}

        <div className='md:mx-20 ml-10 mr-8'>
        <div className='md:text-3xl text-3xl pb-2'>Dec 12,2023</div>
        <div className='bg-red-700 h-[0.1rem]'></div>
        <div className='md:flex hidden'>
        <div className='font-semibold my-10 text-2xl'>Main Schedule</div>
        <div className='font-semibold my-10 text-2xl ml-60 pl-56'>Keynote Speaker</div>
        </div>
        <div className="relative border-l-2 border-red-400 dark:border-red-700">
        <div className='md:flex justify-evenly'>
          <div className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>10:00 AM to 11:30 AM </div>
            <div className='font-bold text-2xl ml-auto mt-1'>Quality and Holistic Education -1 🎓</div>
            <div className='max-w-sm mt-1 text-xl'> Empowering underserved schools with coding/digital labs, STEM learning, sports, and language programs, fostering well-rounded education for a brighter future. </div>
          </div>
          <div className='md:ml-20'>
            <div className='mt-1'>
              <img className='rounded-full h-40 w-40 md:p-0 p-4 object-cover mx-auto' src="/speakers/rohit_arya.png"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Rohit Arya</div>
            </div>
            <div className='text-md text-center'>Vice President, Engineering and<br></br>India Site Head, F5.</div>
          </div>
          </div>
          <div className='md:flex justify-evenly'>
          <div className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>12:00 PM to 1:30 PM </div>
            <div className='font-bold text-2xl ml-auto mt-1'> Quality and Holistic Education -2 🎓</div>
            <div className='max-w-sm mt-1 text-xl'>Nirmaan embarked on its flagship programs under Education, recognizing the urgent need to tackle issues such as inadequate infrastructure, the digital divide, shortage of trained teachers, funding constraints, and the lack of integration with the curriculum.</div>
          </div>
          <div className='md:ml-20'>
            <div className='mt-1'>
              <img className='rounded-full h-40 w-40 md:p-0 p-4 object-cover mx-auto' src="/speakers/shanthi_kumar.png"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Shanthikumar</div>
            </div>
            <div className='text-md text-center'>Director, Workforce Planning &<br></br> Management at EPAM Systems.</div>
          </div>
          </div>
          <div className='md:flex justify-evenly'>
          <div className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>2:30 PM to 4:00 PM  </div>
            <div className='font-bold text-2xl ml-auto mt-1'>Health & Wellbeing 🧠 </div>
            <div className='max-w-sm mt-1 text-xl'> In our mission to create a healthier society, Nirmaan has identified critical needs in healthcare, including limited access, health disparities, and the need for preventative measures. Our health initiatives focus on holistic well-being, disease detection, and early interventions, addressing these challenges head-on.</div>
          </div>
          <div className='md:ml-20'>
            <div className='mt-1'>
              <img className='rounded-full h-40 w-40 md:p-0 p-4 object-cover mx-auto' src="/speakers/Vamshi.png"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Vamshi Krishna</div>
            </div>
            <div className='text-md text-center'>Head of Operations, NATCO Trust.</div>
          </div>
          </div>
          <div className='md:flex justify-evenly'>
          <div className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>4:30 PM to 6:00 PM </div>
            <div className='font-bold text-2xl ml-auto mt-1'>Environment & Sustainability 🌿</div>
            <div className='max-w-sm mt-1 text-xl'>India's environmental challenges are at a critice juncture, demanding immediate attention for the well-being of its citizens and the sustainable future of the notion with alarming statistics such as 61% of groundwater wells being contaminated.</div>
          </div>
          <div className='md:ml-20'>
            <div className='mt-1'>
              <img className='rounded-full h-40 w-40 md:p-0 p-4 object-cover mx-auto' src="/speakers/Kusum.png"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Kusum</div>
            </div>
            <div className='text-md text-center'>Corporate Communications, Branding <br></br>and  CSR Leader| LSR, IIT,<br></br> IMT, Symbiosis.</div>
          </div>
          </div>
          </div>
        <div className='md:text-3xl text-3xl pb-2 mt-5'>Dec 13,2023</div>
        <div className='bg-red-700 h-[0.1rem]'></div>
        <div className='font-semibold my-10 text-2xl'>Main Schedule</div>
        <div className="relative border-l-2 border-red-400 dark:border-red-700">
        <div className='md:flex justify-evenly'>
          <div className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>9:30 AM to 11:00 AM</div>
            <div className='font-bold text-2xl ml-auto mt-1'>Economic Empowerment -1  🙍‍♀️</div>
            <div className='max-w-sm mt-1 text-xl'>Embarking on a journey of empowerment, Nirmaan is committed to uplifting women youth, and persons with disabilities from underprivileged communities. Through a comprehensive approach encompassing skill development, career counseling, and strategic job placements.</div>
          </div>
          <div className='md:ml-20'>
            <div className='mt-1'>
              <img className='rounded-full h-40 w-40 md:p-0 p-4 object-cover mx-auto' src="/speakers/Nitu_narula.png"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Nitu Narula</div>
            </div>
            <div className='text-md text-center'>Chief of Staff & CSR Lead <br></br> at Microsoft IDC.</div>
          </div>
          </div>
          <div className='md:flex  justify-evenly'>
          <div className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>11:30 PM to 1:00 PM </div>
            <div className='font-bold text-2xl ml-auto mt-1'>Economic Empowerment -2  🙍‍♀️</div>
            <div className='max-w-sm mt-1 text-xl'> Embracing Diversity and Inclusion - Empowering Persons with Disabilities and LGBTQ+ Communities, fostering an equitable and inclusive society for all.</div>
          </div>
          <div className='md:ml-20'>
            <div className='mt-1'>
              <img className='rounded-full h-40 w-40 md:p-0 p-4 object-cover mx-auto' src="/speakers/nithin.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Nitin Sharma</div>
            </div>
            <div className='text-md text-center'>Technology Counsel (Sr. Director, <br></br>Technology/Patent Counsel).</div>
          </div>
          </div>
          <div className='md:flex justify-evenly'>
          <div className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>2:00 PM to 3:30 PM  </div>
            <div className='font-bold text-2xl ml-auto mt-1'> Tech for Social Good</div>
            <div className='max-w-sm mt-1 text-xl'>Since our inception, Nirmaan has integrated on-ground operations with a tech-driven approach. Stemming from the conviction of our initial team members in the transformative power of technology to address societal needs, we established a dedicated Tech/Al for Good vertical in 2017. This strategic move aimed to leverage tech solutions for societal impact at scale.</div>
          </div>
          <div className='md:ml-20'>
            <div className='mt-1'>
              <img className='rounded-full h-40 w-40 md:p-0 p-4 object-cover mx-auto' src="/speakers/Prashanth.png"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Prashanth Achanta</div>
            </div>
            <div className='text-md text-center'>President and Co-founder <br></br> Algoleap technologies.</div>
          </div>
          </div>
          <div className='md:flex justify-evenly'>
          <div className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>4:00 PM to 5:30 PM </div>
            <div className='font-bold text-2xl ml-auto mt-1'>Rural Development and More 🏞️</div>
            <div className='max-w-sm mt-1 text-xl'>To propel India towards economic development, a pivotal focus on sustainable village development through the Provision of Urban Amenities in Rural Areas (PURA) is imperative. With approximately 83.3 million residents in rural areas, the integration of basic amenities is paramount to transform villages into thriving centers of growth.</div>
          </div>
          <div className='md:ml-20'>
            <div className='mt-1'>
              <img className='rounded-full h-40 w-40 md:p-0 p-4 object-cover mx-auto' src="/speakers/madan.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'></div>
            </div>
            <div className='text-md text-center'></div>
          </div>
          </div>
        </div>
        </div>
      </div>

      {/* Speakers start here -------------------- */}
      {/* <div className="mb-20">
              <div
                className="flex justify-center font-bold md:text-5xl text-4xl mt-20 mb-10  "
                id="Speakers"
              >
                Speakers
              </div>
              <div className="grid md:grid-cols-3 grid-cols-1 gap-10 px-16">
                {SpeakersData.map((speaker, index) => (
                  <div key={index} className="md:p-5 md:pb-0">
                    <img
                      className="rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto"
                      src={speaker.imageSrc}
                      alt={speaker.name}
                    />
                    <div className="text-center text-xl font-semibold text-red-500">
                      {speaker.name}
                    </div>
                    <div className="text-md text-center">{speaker.role}</div>
                  </div>
                ))}
              </div>
            </div> */}
            {/* Speakers  ends here -------------*/}

            {/* Partners start here -------------- */}
            {/* <div
              className="font-custom pb-20 bg-gradient-to-b from-pink-50 to-blue-50"
              id="Partners"
            >
              <h2 className="font-custom text-5xl text-center pt-10 font-bold">
                Partners
              </h2>
              <div className="font-custom grid sm:grid-cols-3 px-16 gap-x-6 gap-y-16 grid-cols-1  mt-24">
                {PartnersData.map((Partners, index) => (
                  <div key={index}>
                    <p class="text-2xl items-center justify-center flex">
                      {" "}
                      {Partners.corporatename}
                      {Partners.subName}
                      {Partners.subName2}
                    </p>
                  </div>
                ))}
              </div>
            </div> */}
            {/* Partners ends here -------- */}

      {/* Venue */}
      <div id="Venue">
      <div className='md:text-5xl text-4xl text-center py-10 font-bold  '>Venue</div>
      <div className='grid md:grid-cols-5'>
      <div className='col-span-2'>
        <div>
          <img className='w-full' src="/png/ven.jpg"></img>
          <div className='px-5'>
          <div className='text-xl font-bold my-2 '>Venue</div>
          <div className=' font-bold'>Swagath-De-Royal</div>
          <div>Kothaguda Cross Road Cyberabad, Gachibowli - Miyapur Rd, Hyderabad, Telangana 500081</div>
          </div>
          <div className='flex justify-center'>
           <a className='bg-red-500 py-1.5 px-6 w-fit hover:bg-red-400 text-white my-6 font-bold rounded-full' target='_blank'  href='https://www.google.com/maps/dir//Swagath-De-Royal+Kothaguda+Cross+Road+Cyberabad+Gachibowli+-+Miyapur+Rd+Hyderabad,+Telangana+500081/@17.460088,78.3660471,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bcb93c93eb2544d:0xac79d3f07c3d06fb!2m2!1d78.3661049!2d17.4600717?entry=ttu'> Get Directions</a> 
          </div>
        </div>
      </div>
      <div data-aos="fade-up" className='col-span-3'>
      <iframe className='md:rounded-br-[2rem] md:rounded-bl-none rounded-b-[2rem] h-[300px] md:h-full' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.9920815002374!2d78.36347217385034!3d17.460093100709106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93c93eb2544d%3A0xac79d3f07c3d06fb!2sSwagath-De-Royal!5e0!3m2!1sen!2sin!4v1690264358387!5m2!1sen!2sin" style={{border:"0", width:"100%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      </div>
      </div>
    </div>
    </div>
    </div>
    <a href='#top' className='fixed bottom-4 md:right-8 right-4'>
  <img className='md:h-12 h-10' src="/png/arrow-circle-up.svg" alt="Arrow Up Icon"/>
</a>
  </div>
  );
}

{/* Partners */}
      {/* <div className='font-custom pb-20 bg-gradient-to-b from-pink-50 to-blue-50'id='Partners'>
        <div className='font-custom text-5xl text-center pt-10 font-bold'>Partners</div>
        <div className='font-custom grid sm:grid-cols-3 px-16 gap-x-6 gap-y-16 grid-cols-1  mt-24'>
        <div className='font-custom flex justify-center items-center text-3xl ' >ADAMA</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >ADP</div>
        <div><div className='font-custom flex justify-center items-center text-3xl'>Advance</div> <span className='font-custom flex justify-center items-center text-3xl'>Auto Parts</span></div>
        <div className='font-custom flex justify-center items-center text-3xl ' >AVEVA</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Bosch</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Carrier</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >CHUBB</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Cognizant</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Colruyt</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >DBS TECH</div>
        <div><div className='font-custom flex justify-center items-center text-3xl'>D E Shaw</div> <span className='font-custom flex justify-center items-center text-3xl'>& Co</span></div>
        <div className='font-custom flex justify-center items-center text-3xl ' >epam</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >experian</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >F5 Networks</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >firstsource</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >HCLFoundation</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >HSBC</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Infosys</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Inorbit</div>
        <div><div className='font-custom flex justify-center items-center text-3xl'>Indian Oil</div> <span className='font-custom flex justify-center items-center text-3xl'>Corporation</span> <span className='font-custom flex justify-center items-center text-3xl'> Limited</span></div>
        <div className='font-custom flex justify-center items-center text-3xl ' >InteractiveBrokers</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Invesco</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Micron</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Microsoft</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >NOVARTIS</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >NTT Data</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >opentext</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Pegasystems</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >PEPSICO</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Qualcomm</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Rotary</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >savills</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >SenecaGlobal</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >servicenow</div>
        <div><div className='font-custom flex justify-center items-center text-3xl'>SILICON</div> <span className='font-custom flex justify-center items-center text-3xl'>LABS</span></div>
        <div><div className='font-custom flex justify-center items-center text-3xl'>STATE</div> <span className='font-custom flex justify-center items-center text-3xl'>STREET</span></div>
        <div className='font-custom flex justify-center items-center text-3xl ' >synchrony</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >synopsys</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >STELLANTIS</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >UST</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >valueMomentum</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >verizon</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >virtusa</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >vmware</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >WELLSFARGO</div>
      </div>
      </div> */}
{/* Speakers */}
      {/* <div className='mb-20'>
        <div className='flex justify-center font-bold md:text-5xl text-4xl mt-20 mb-10  ' id="Speakers">Speakers</div>
        <div className='grid md:grid-cols-3 grid-cols-1 gap-10 px-16'>
        <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 md:p-0 p-4 object-cover mx-auto' src="/speakers/madan.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Madan Pillutla</div>
            </div>
            <div className='text-md text-center'>Dean & Professor, Indian School of Business.</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 md:p-0 p-4 object-contain mx-auto' src="/speakers/archana.png"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Archana Suresh</div>
            </div>
            <div className='text-md text-center'>Director, Telangana Social Impact Group (T-SIG).</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/speakers/jitendra.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Jitendra Chakravarthy Putcha</div>
            </div>
            <div className='text-md text-center'>EVP & Global Head, LTI Mindtree</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/speakers/neeraja.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Neeraja Vijender</div>
            </div>
            <div className='text-md text-center'>Director & Head of Operations, HYSEA.</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/speakers/Divya.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Divya Devarajan IAS</div>
            </div>
            <div className='text-md text-center'>Project Officer, Integrated Tribal Development Agency at Government of Telangana.</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/speakers/jebakumar.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Jebakumar Swvigaradoss</div>
            </div>
            <div className='text-md text-center'>Senior Director at Servicenow.</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/speakers/prashanth.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Prashanth Nandella</div>
            </div>
            <div className='text-md text-center'>COO at Firstsource.</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/speakers/nithin.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Nitin Sharma</div>
            </div>
            <div className='text-md text-center'>Technology Counsel (Sr. Director, Technology/Patent Counsel).</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/speakers/chandrashekar.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Shri. G. Chandrashekar IFoS</div>
            </div>
            <div className='text-md text-center'>Sr. Director, Engineering at Qualcomm.</div>
          </div>
        </div>
      </div> */}

export default App;
